import React from 'react';
// import firstSection from "../../../components/assets/img/pages/landing/first-section-background.png";
const system = process.env.GATSBY_SYSTEM_WEB;

const FirstSection = () => {
  
  return (
    <section className="first">
      <div className="left">
        <h1 className="title yellow-font bold">O melhor <span className="white-font bold">presente.</span></h1>
        <h1 className="title yellow-font bold">No seu tempo e de onde você estiver</h1>
        <p className="description white-font">Gifthy é o seu assistente para presentear. Foi feito para simplificar a compra de presentes para amigos, parentes, colegas e quem você quiser. É uma rede social que lembra das datas comemorativas, como aniversários ou um evento especial, e também orienta na escolha do presente ideal, de acordo com o desejo ou <span className="bold">life style</span> do presenteado. Também permite comprar aquele "presente especial", através de "vaquinha" entre os participantes.</p>
        <h2 className="subtitle white-font bold">Vamos lá! <span className="yellow-font bold">Junte-se a nós agora mesmo!</span></h2>
        <a className="primary-button white-font bold" href={`${system}/login`} target="_blank">ENTRAR</a>
      </div>
    </section>
  );
};

export default FirstSection;